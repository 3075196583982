import React from 'react';
import classNames from 'classnames';
import Download from './download';

const Footer = () => {
	return (
		<div
			className={classNames(
				'footer bg-black flex flex-col items-center justify-center pt-8 pb-8 text-xs',
			)}
		>
			<Download />
			<div className={classNames('meta flex flex-col md:flex-row')}>
				<p className={classNames('text-gray-400 mr-4 mb-4 md:mb-0')}>&copy; 2022 Kingstinct AB</p>
				<p className={classNames('text-gray-400')}>
					Made by{' '}
					<a href="https://kingstinct.com" className={classNames('schedulist-primary')}>
						Kingstinct
					</a>
					<span className={classNames('ml-2 mr-2')}>–</span>
					<a href="mailto:hello@schedulist.app" className={classNames('schedulist-primary')}>
						hello@schedulist.app
					</a>
					<span className={classNames('ml-2 mr-2')}>–</span>
					<a href="https://docs.google.com/document/d/e/2PACX-1vSQJCHMhDDk7u5_TPq2QoyFwjWfwzrKnDklj0iVvfZKFfroQ7UtFNc1xV1pDBP1Opv9kc0Oapw_h2FI/pub" className={classNames('schedulist-primary')}>
						Privacy Policy
					</a>
				</p>
			</div>
		</div>
	);
};

export default Footer;
