import React from 'react'
import Section from '../components/section'
import styled from 'styled-components';
import BottomBullets from '../components/bottom-bullets';
import Integrations from '../components/integrations';
import Hero from '../components/hero';
import { Helmet } from 'react-helmet';


const heroContent = {
    id: 0,
    type: 'intro',
    name: 'productivity-reinvented',
    legend: 'Your day in one place',
    title: 'Unify your tasks, calendars and workflows with Schedulist',
    background: '/images/bg.jpg',
    text: 'Are you using multiple apps to plan your day? Schedulist is the daily planner that gives your everything in one easy-to-use app.',
    class: 'flex flex-col items-center justify-start font-sans min-h-96 bg-gray-50 lg:pt-10 lg:pb-20 lg:bg-hero dark:bg-black bg-cover bg-center',
    animate: 'fadeIn',
};

const content = [
    {
        id: 1,
        name: 'one-daily-agenda',
        title: 'Effortless',
        asset: '/images/app-screens/01',
        darkAsset: '/images/app-screens/01_dark',
        text: 'Just type to add tasks. Just swipe to complete and plan tasks.',
        class: 'lg:items-center lg:flex lg:flex-row-reverse lg:justify-center',
        align: 'right',
        animate: 'fadeInLeft',
    },
    {
        id: 2,
        name: 'manage-workload',
        title: 'Add Anything',
        asset: '/images/app-screens/02',
        darkAsset: '/images/app-screens/02_dark',
        text: 'Intuitively add images, files and links. Add notes and comments - with markdown and checklist support.',
        class: 'lg:items-center lg:flex lg:flex-row lg:justify-center',
        align: 'left',
        animate: 'fadeInRight',
    },
    {
        id: 3,
        name: 'organise-and-collaborate-with-lists',
        title: 'Shared lists',
        asset: '/images/app-screens/03',
        darkAsset: '/images/app-screens/03_dark',
        text: 'Invite family, friends and colleagues and get things done together.',
        class: 'lg:items-center lg:flex lg:flex-row-reverse lg:justify-center',
        align: 'right',
        animate: 'fadeInLeft',
    },
    {
        id: 4,
        name: 'swipable-and-talkable',
        title: 'Schedule',
        asset: '/images/app-screens/04',
        darkAsset: '/images/app-screens/04_dark',
        text: 'Recurring tasks, deadlines and your calendars in one place.',
        class: 'lg:items-center lg:flex lg:flex-row lg:justify-center',
        align: 'left',
        animate: 'fadeInRight',
    },
    {
        id: 5,
        name: 'less-stress-morepeace-of-mind',
        title: 'Reduce Overwhelm',
        asset: '/images/app-screens/05',
        darkAsset: '/images/app-screens/05_dark',
        text: 'In every design detail we strive to reduce overwhelm and simplify. Get more done - with less stress.',
        class: 'lg:items-center lg:flex lg:flex-row-reverse lg:justify-center',
        align: 'right',
        animate: 'fadeInLeft',
    },
];

const pageTitle = 'Unify your tasks, calendars and workflows with Schedulist';
const pageDescription = 'Are you using multiple apps to plan your day? Schedulist is the daily planner that gives your everything in one easy-to-use app.';
const pageUrl = 'https://www.schedulist.app/integrations';

const TodoAppForWeb = styled(({className}) => {
	return (
        <div className='box-border'>
             <Helmet>
				<title>{ pageTitle }</title>
				<meta
					name="description"
					content={pageDescription}
				/>

				<meta property="og:title" content={pageTitle} />
				<meta property="og:description" content={pageDescription} />
				<meta property="og:url" content={pageUrl} />
				<meta name="twitter:url" content={pageUrl} />
				<meta name="twitter:title" content={pageTitle} />
				<meta name="twitter:description" content={pageDescription} />
				<meta property="og:site_name" content={pageTitle} />
				<meta name="twitter:image:alt" content={pageDescription} />
				<meta property="twitter:text:title" content={pageTitle} />
			</Helmet>
            <div className='flex flex-col'>
                <Hero item={heroContent}></Hero>
                <Integrations />
                <Section content={content} />

                <BottomBullets />

            </div>
        </div>
	);
})`
`;

export default TodoAppForWeb
