import React, { useEffect } from 'react'
import classNames from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const pageTitle = 'How can I save links to read later to my calendar? | Schedulist'
const pageDescription = 'The Effortless Productivity Platform'
const pageUrl = 'https://www.schedulist.app/how-can-i-save-links-for-later-calendar'

const SaveForLaterCalendar = styled(({className}) => {

    useEffect(() => {
        window.scrollTo({top:0 })
    }, [])
	return (
        <div className={classNames(
            className,
            'page',
        )}>
             <Helmet>
                <title>{ pageTitle }</title>
                <meta
                    name="description"
                    content={pageDescription}
                />

                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:url" content={pageUrl} />
                <meta name="twitter:url" content={pageUrl} />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta property="og:site_name" content={pageTitle} />
                <meta name="twitter:image:alt" content={pageDescription} />
                <meta property="twitter:text:title" content={pageTitle} />
            </Helmet>
            <div className='box-border'>
                <div className='flex flex-col'>
                        <ScrollAnimation animateOnce animateIn="fadeIn">
                            <div id="cover" className="mx-auto md:h-screen flex flex-col items-center justify-start font-sans min-h-96 bg-gray-50 lg:pt-10 lg:pb-20 lg:bg-hero dark:bg-black lg:bg-cover bg-center bg-opacity-40 bg-cover" style={{ backgroundImage : 'url(/images/hero_about.jpg)'}}>
                               <div
									className={classNames(
										'container max-w-3xl mx-auto mt-40 mb:mt-0 mb-0 md:mb-8',
									)}
								>
                                    <h1 className="p-0 text-3xl font-bold text-left text-white leading-normal lg:mx-auto lg:text-5xl pl-8 pr-8 mb-8">
                                    How can I save links to read later to my calendar?
                                    </h1>
                                    <p className="p-0 font-sans text-base leading-normal text-left text-gray-400 lg:text-gray-400 pl-8 pr-8 mb-16">
                                    With Schedulist you can easily schedule read later things for later together with your tasks and calendar. So you can easily see only what you need at any point in time, yet stay assured that you don’t miss to follow up on it.
                                    </p>
                                    <p className="p-0 font-sans text-base leading-normal text-left text-gray-400 lg:text-gray-400 pl-8 pr-8 mb-16">
                                    With Schedulist you can easily keep track of links to read later together with your tasks. You can do it:
                                    - By using our Web Extension (available for Chrome, Safari, Firefox, Edge and Brave)
                                    - By copy/pasting a link into the app (with our clipboard integration)
                                    - (Coming soon) By using the mobile app share extension
                                    </p>
                                    
                                </div>
                            </div>
                        </ScrollAnimation>
                </div>
            </div>
        </div>
	);
})`
    #cover {
        .container {
            z-index: 1;
        }
        &:after {
            display: block;
            background: rgba(0,0,0,0.7);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            pointer-events: none;
            @media (max-width: 600px) {
                height: calc(100% + 123px);
            }
        }
    }
`;

export default SaveForLaterCalendar