import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const List = [
	{
		icon: '/images/app-icons/trello.svg',
		name: 'Trello',
		class: 'trello',
		url: 'https://www.trello.com',
	},
	{
		icon: '/images/app-icons/github.svg',
		class: 'github-issues',
		name: 'Github',
		url: 'https://www.github.com',
	},
	{
		icon: '/images/app-icons/zapier.svg',
		name: 'zapier',
		class: 'app-zapier',
		url: 'https://zapier.com/developer/public-invite/144848/4396c9355591a4967e49b87c7fe75d23/',
	},
	{
		icon: '/images/app-icons/google-calendar.svg',
		class: 'google-calendar',
		name: 'Google Calendar',
		url: 'https://www.google.com/calendar',
	},
	{
		icon: '/images/app-icons/office-365.svg',
		app: 'office365-calendar',
		name: 'Office365',
		url: 'https://www.office365.com',
	},
	{
		icon: '/images/app-icons/chrome.svg',
		class: 'chrome-read-later-extension',
		name: 'Chrome',
		url: 'https://chrome.google.com/webstore/detail/schedulist/knclkjigbiflbnjjhgghkmfngcpogpgp',
	},
	{
		icon: '/images/app-icons/firefox.svg',
		class: 'firefox-read-later-extension',
		name: 'Firefox',
		url: 'https://addons.mozilla.org/en-US/firefox/addon/schedulist/',
	},
	/*{
		icon: '/images/app-icons/safari.svg',
		class: 'app-safari',
		name: 'Safari',
		url: 'https://www.apple.com/safari',
	},*/
];

const Integrations = styled(({ className }) => {
	return (
		<div
			className={classNames(
				className,
				'integrations md:flex md:flex-col items-center justify-center mt-0 mb-0 pt-8 pb-8 pl-8 pr-8 bg-gray-100 dark:bg-black',
			)}
		>
			<p className="p-0 text-xxs font-bold uppercase text-center text-gray-600 dark:text-gray-400 lg:mx-auto lg:text-base pl-8 pr-8 mb-8">
				Integrations
			</p>
			<div className="grid grid-cols-3 md:grid-cols-0 md:flex md:flex-row md:w-full">
				{List.map((item) => (
					<div
						key={item.url}
						className={classNames(
							'integration bg-opacity-60 mb-4 md:mb-0 md:flex-1',
							item.class,
							item > 1 ? '' : 'mr-2 ml-2',
						)}
					>
						<a
							href={item.url}
							className={classNames(
								'flex flex-col items-center w-full justify-center p-2',
							)}
						>
							<img src={item.icon} alt="icon" className={classNames('max-h-8')} />
							<p
								className={classNames(
									'text-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:text-gray-300 flex flex-col items-center justify-center pt-2 text-xxs',
								)}
							>
								{item.name}
							</p>
						</a>
					</div>
				))}
			</div>
		</div>
	);
})`
	flex: 1;
	&:hover {
		.item {
			opacity: 0.7;
		}
	}
	.integration {
		@media (min-width: 768px) {
			// min-width: 130px;
		}
		transition: all .2s ease-in-out;
		transform: scale(1);
		&:hover {
			opacity: 1;
			transform: scale(1.1);
		}
		
		&.github-issues {
			@media (prefers-color-scheme: dark) {
				filter:  brightness(0) invert(1);
			}
		}
	}

	.integration img {
	}
`;

export default Integrations;
