import React from 'react'
import classNames from 'classnames';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

const BottomBullets = styled(({className}) => {
	return (
                <div
                    className={classNames(
                        className,
                        'section align-center justify-center lg:items-center lg:flex lg:flex-row-reverse lg:justify-center py-20',
                    )}
                >
                    <ScrollAnimation
						animateOnce
						animateIn="fadeIn"
					>
                        <p className="p-0 text-xxs font-bold uppercase text-center text-gray-600 dark:text-gray-400 lg:mx-auto lg:text-base mb-8">
                            And much more..
                        </p>

                        <div className="flex flex-wrap overflow-hidden rounded-lg border border-gray-200 dark:border-gray-800 border-solid py-16 px-4 lg:px-16 mx-8 lg:mx-0">
                            <div className="w-full lg:w-1/3 overflow-hidden mb-8 lg:mb-0">
                                <ul className="list-none">
                                    <li className="mx-0 mb-8 px-0 py-0">
                                        <p className="text-base font-semibold leading-normal text-center schedulist-primary lg:pb-1 lg:text-base lg:pl-0 lg:font-bold lg:text-center mb-0">
                                            Meeting Notes
                                        </p>
                                        <p className="p-5 pt-0 pb-0 px-4 lg:px-10 mx-auto text-xs leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-xs lg:text-center lg:p-0 lg:pl-0 lg:pr-0">
                                            Effortlessly collect notes before or during a meeting - it automatically turns into a follow up task
                                        </p>
                                    </li>
                                    <li className="mx-0 mb-8 px-0 py-0">
                                        <p className="text-base font-semibold leading-normal text-center schedulist-primary lg:pb-1 lg:text-base lg:pl-0 lg:font-bold lg:text-center mb-0">
                                            Meetings - Glanceable and Joinable
                                        </p>
                                        <p className="p-5 pt-0 pb-0 px-4 lg:px-10 mx-auto text-xs leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-xs lg:text-center lg:p-0 lg:pl-0 lg:pr-0">
                                            Schedulist counts down to the next meeting, and let's you jump right in with one click
                                        </p>
                                    </li>
                                    
                                    <li className="mx-0 px-0 py-0">
                                        <p className="text-base font-semibold leading-normal text-center schedulist-primary lg:pb-1 lg:text-base lg:pl-0 lg:font-bold lg:text-center mb-0">
                                            Web Extension
                                        </p>
                                        <p className="p-5 pt-0 pb-0 px-4 lg:px-10 mx-auto text-xs leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-xs lg:text-center lg:p-0 lg:pl-0 lg:pr-0">
                                            Add links with one click to Schedulist
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full lg:w-1/3 overflow-hidden mb-8 lg:mb-0">
                                <ul className="list-none">
                                    <li className="mx-0 mb-8 px-0 py-0">
                                        <p className="text-base font-semibold leading-normal text-center schedulist-primary lg:pb-1 lg:text-base lg:pl-0 lg:font-bold lg:text-center mb-0">
                                            Clipboard integration
                                        </p>
                                        <p className="p-5 pt-0 pb-0 px-4 lg:px-10 mx-auto text-xs leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-xs lg:text-center lg:p-0 lg:pl-0 lg:pr-0">
                                            Seamlessly paste links and text straight from your clipboard
                                        </p>
                                    </li>
                                    <li className="mx-0 mb-8 px-0 py-0">
                                        <p className="text-base font-semibold leading-normal text-center schedulist-primary lg:pb-1 lg:text-base lg:pl-0 lg:font-bold lg:text-center mb-0">
                                            Gamification
                                        </p>
                                        <p className="p-5 pt-0 pb-0 px-4 lg:px-10 mx-auto text-xs leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-xs lg:text-center lg:p-0 lg:pl-0 lg:pr-0">
                                            Get motivated by Achievements and Statistics of your progress
                                        </p>
                                    </li>
                                    <li className="mx-0 px-0 py-0">
                                        <p className="text-base font-semibold leading-normal text-center schedulist-primary lg:pb-1 lg:text-base lg:pl-0 lg:font-bold lg:text-center mb-0">
                                            Intuitive bulk actions
                                        </p>
                                        <p className="p-5 pt-0 pb-0 px-4 lg:px-10 mx-auto text-xs leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-xs lg:text-center lg:p-0 lg:pl-0 lg:pr-0">
                                            Schedule, complete and organize many tasks at a time
                                        </p>
                                    </li>
                                    <li className="mx-0 px-0 py-0">
                                        <p className="text-base font-semibold leading-normal text-center schedulist-primary lg:pb-1 lg:text-base lg:pl-0 lg:font-bold lg:text-center mb-0">
                                            Syncs seamlessly across all your devices
                                        </p>
                                        <p className="p-5 pt-0 pb-0 px-4 lg:px-10 mx-auto text-xs leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-xs lg:text-center lg:p-0 lg:pl-0 lg:pr-0">
                                            Available for iOS, Android, web and M1 Macs
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full lg:w-1/3 overflow-hidden">
                                <ul className="list-none">
                                    <li className="mx-0 mb-8 px-0 py-0">
                                        <p className="text-base font-semibold leading-normal text-center schedulist-primary lg:pb-1 lg:text-base lg:pl-0 lg:font-bold lg:text-center mb-0">
                                            Notes and comments
                                        </p>
                                        <p className="p-5 pt-0 pb-0 px-4 lg:px-10 mx-auto text-xs leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-xs lg:text-center lg:p-0 lg:pl-0 lg:pr-0">
                                            With markdown and checklist support
                                        </p>
                                    </li>
                                    <li className="mx-0 mb-8 px-0 py-0">
                                        <p className="text-base font-semibold leading-normal text-center schedulist-primary lg:pb-1 lg:text-base lg:pl-0 lg:font-bold lg:text-center mb-0">
                                            Keyboard shortcuts
                                        </p>
                                        <p className="p-5 pt-0 pb-0 px-4 lg:px-10 mx-auto text-xs leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-xs lg:text-center lg:p-0 lg:pl-0 lg:pr-0">
                                            Easy-to-use shortcuts to speed up your workflow (on web, macOS and iPad)
                                        </p>
                                    </li>

                                    <li className="mx-0 px-0 py-0">
                                        <p className="text-base font-semibold leading-normal text-center schedulist-primary lg:pb-1 lg:text-base lg:pl-0 lg:font-bold lg:text-center mb-0">
                                            Drag-and-drop
                                        </p>
                                        <p className="p-5 pt-0 pb-0 px-4 lg:px-10 mx-auto text-xs leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-xs lg:text-center lg:p-0 lg:pl-0 lg:pr-0">
                                            First-class support for files and images with drag-and-drop support
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </ScrollAnimation>
                </div>
	);
})`
`;

export default BottomBullets
