import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import Toggle from './components/ThemeToggle';

ReactDOM.render(
	<React.StrictMode>
		{/* <ThemeProvider>
			<Background>
				<div className="fixed right-0 top-0 mr-4 mt-4 md:mr-6 md:mt-6 z-30 opacity-0">
					<Toggle />
				</div>
				<App />
			</Background>
		</ThemeProvider> */}
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();