import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/header';
import {Helmet} from "react-helmet";
import Footer from './components/footer';
import './App.css';
import './index.css';
import Routes from './routes';

function App() {
	return (
		<div className="App">
			<Helmet>
			<title>Schedulist</title>
    <meta
      name="description"
      content="The Effortless Productivity Platform"
    />

    <meta property="og:title" content="Schedulist" />
    <meta property="og:image" content="%PUBLIC_URL%/images/bg.png" />
    <meta property="og:description" content="The Effortless Productivity Platform" />
    <meta property="og:url" content="https://www.schedulist.app" />
    <meta name="twitter:url" content="https://www.schedulist.app" />
    <meta name="twitter:title" content="Schedulist" />
    <meta name="twitter:card" content="app" />
    <meta name="twitter:description" content="The Effortless Productivity Platform" />
    <meta name="twitter:image" content="%PUBLIC_URL%/images/bg.png" />
    <meta property="og:site_name" content="Schedulist" />
    <meta name="twitter:image:alt" content="The Effortless Productivity Platform" />
    <meta property="og:type" content="website" />
    <meta property="twitter:app:id:googleplay" content="com.kingstinct.schedulist" />
    <meta property="twitter:app:url:iphone" content="schedulist://" />
    <meta property="twitter:app:url:googleplay" content="schedulist://" />
    <meta property="twitter:site" content="@schedulist" />
    <meta property="twitter:text:title" content="Schedulist" />
    
    <meta property="twitter:app:id:ipad" content="1568459470" />
    <meta property="twitter:app:id:iphone" content="1568459470" />
    <meta property="twitter:app:url:ipad" content="schedulist://" />
			</Helmet>
			<Router>
				<Header />
				<Routes />
				<Footer />
			</Router>
		</div>
	);
}

export default App;