import React from 'react';
import { Route, Switch } from 'react-router';
import Home from './pages/home';
import About from './pages/about';
import TodoAppForWeb from './pages/integrations';
import CrossPlatformPlanner from './pages/cross-platform-planner';
import GoogleCalendar from './pages/google-calendar';
import OutlookCalendar from './pages/outlook-calendar';
import GoogleCalendarPrivate from './pages/google-calendar-private';
import OutlookCalendarPrivate from './pages/outlook-calendar-private';
import AreMyTasksPrivate from './pages/are-my-tasks-private';
import SaveForLater from './pages/how-can-i-save-links-for-later';
import SaveForLaterCalendar from './pages/how-can-i-save-links-for-later-calendar';
import SimpleTodoApp from './pages/simple-todo';

function Routes() {
	return (
      <Switch>
        <Route exaxt path="/about" component={About} />
        <Route exaxt path="/todo-app-with-integrations" component={TodoAppForWeb} />
        <Route exaxt path="/cross-platform-daily-planner" component={CrossPlatformPlanner} />
        <Route exaxt path="/google-calendar" component={GoogleCalendar} />
        <Route exaxt path="/outlook-calendar" component={OutlookCalendar} />
        <Route exaxt path="/google-calendar-private" component={GoogleCalendarPrivate} />
        <Route exaxt path="/outlook-calendar-private" component={OutlookCalendarPrivate} />
        <Route exaxt path="/are-my-tasks-private" component={AreMyTasksPrivate} />
        <Route exaxt path="/how-can-i-save-links-for-later" component={SaveForLater} />
        <Route exaxt path="/how-can-i-save-links-for-later-calendar" component={SaveForLaterCalendar} />
        <Route exaxt path="/simple-todo" component={SimpleTodoApp} />
        <Route path="/" component={Home} />
      </Switch>
	);
}

export default Routes;