import classNames from 'classnames';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import Download from './download';

const Section = styled(({ className, content }) => {
	
	const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
	// Current implementation dont support auto-detect when switching light vs dark mode
	//
	// window
	// .matchMedia("(prefers-color-scheme: dark)")
	// .addEventListener("change", function (e) {
	// 	const colorScheme = e.matches ? "dark" : "light";
	// 	console.log(colorScheme);

	// 	if (colorScheme === "dark") {
	// 		do something
	// 	} else {
	// 		do something else
	// 	}
	// });

	return (
		<>
			{content.map((item) => {
				return (
					<ScrollAnimation
						key={item.id}
						animateOnce
						animateIn={item.animate ? item.animate : 'none'}
					>
						<div
							className={classNames(
								className,
								'section align-center justify-center',
								item.type === 'intro' ? 'md:h-screen' : 'container mx-auto',
								item.class,
								item.align,
							)}
							id={item.name}
							style={{ backgroundImage: `url(${item.background}` }}
						>
							{item.type === 'intro' ? (
								<div
									className={classNames(
										'container max-w-3xl mx-auto mt-40 mb:mt-0 mb-0 md:mb-8',
									)}
								>
									<h1>
										<p className="p-0 text-xs font-bold uppercase text-left schedulist-primary lg:mx-auto lg:text-2xl pl-8 pr-8 mb-8">
											{item.legend}
										</p>
										<p className="p-0 text-3xl font-bold text-left text-white leading-normal lg:mx-auto lg:text-5xl pl-8 pr-8 mb-8">
											{item.title}
										</p>
									</h1>
									<h2 className="p-0 font-sans text-base leading-normal text-left text-gray-400 lg:text-gray-400 pl-8 pr-8">
										{item.text}
									</h2>
									<Download />
									{item.asset ? (
										<div className="ml-10 lg:ml-0 lg:w-3/6">
											<img
												alt="demo"
												className="lg:mt-24 lg:mb-20"
												src={item.asset}
											/>
										</div>
									) : null}
								</div>
							) : null}
							{item.align === 'left' ? (
								<>
									<div className="pl-8 pr-8 md:pl-0 md:pr-0 pt-8 pb-8 mb:pt-0 mb:pb-0 lg:w-2/6 lg:ml-20 lg:-mt-10 lg:flex lg:flex-col lg:justify-center lg:items-start">
										<p className="p-5 text-2xl font-semibold leading-normal text-center schedulist-primary lg:pb-4 lg:text-3xl lg:pl-0 lg:font-bold lg:text-left mb-0">
											{item.title}
										</p>
										<p className="p-5 pt-0 pb-0 pl-10 pr-10 text-base leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-lg lg:text-left lg:p-0 lg:pl-0 lg:pr-0">
											{item.text}
										</p>
									</div>
									{item.asset && item.darkAsset ? (
										<div className="ml-0 lg:ml-0 lg:w-3/6 pt-8 pb-8 mb:pt-0 mb:pb-0">

											{ userPrefersDark ?
												<img
													alt="demo"
													className="md:mt-32 md:mb-32 mx-auto dark:visible feature-image"
													src={item.darkAsset}
													srcSet={`${item.darkAsset}.png 1x, ${item.darkAsset}@2x.png 2x`}
												/>
												 :
												<img
													alt="demo"
													className="md:mt-32 md:mb-32 mx-auto light:visible feature-image"
													src={item.asset}
													srcSet={`${item.asset}.png 1x, ${item.asset}@2x.png 2x`}
												/>
											}
										</div>
									) : null}
								</>
							) : null}
							{item.align === 'right' ? (
								<>
									<div className="pl-8 pr-8 md:pl-0 md:pr-0 pt-8 pb-8 mb:pt-0 mb:pb-0 lg:w-2/6 lg:ml-20 lg:-mt-10 lg:flex lg:flex-col lg:justify-center lg:items-start">
										<p className="p-0 pt-8 md:pt-0 md:p-5 text-2xl font-semibold leading-normal text-center schedulist-primary lg:pb-4 lg:text-3xl lg:pl-0 lg:font-bold lg:text-left mb-0">
											{item.title}
										</p>
										<p className="p-5 pt-0 pb-0 pl-10 pr-10 text-base leading-normal text-center text-gray-400 lg:w-5/6 lg:pb-0 lg:text-lg lg:text-left lg:p-0 lg:pl-0 lg:pr-0">
											{item.text}
										</p>
									</div>
									{item.asset && item.darkAsset ? (
										<div className="ml-0 lg:ml-0 lg:w-3/6 pt-8 pb-8 mb:pt-0 mb:pb-0">
											{ userPrefersDark ?
												<>
													<img
														alt="demo"
														className="md:mt-32 md:mb-32 mx-auto dark:visible feature-image"
														src={item.darkAsset}
														srcSet={`${item.darkAsset}.png 1x, ${item.darkAsset}@2x.png 2x`}
													/>
												</>
											:
												<img
													alt="demo"
													className="md:mt-32 md:mb-32 mx-auto light:visible feature-image"
													src={item.asset}
													srcSet={`${item.asset}.png 1x, ${item.asset}@2x.png 2x`}
												/>
											}
										</div>
									) : null}
								</>
							) : null}
						</div>
					</ScrollAnimation>
				);
			})}
		</>
	);
})``;

export default Section;