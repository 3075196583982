import React, { useEffect } from 'react'
import classNames from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const pageTitle = 'How are my tasks kept private and secure? | Schedulist'
const pageDescription = 'The Effortless Productivity Platform'
const pageUrl = 'https://www.schedulist.app/are-my-tasks-private'

const AreMyTasksPrivate = styled(({className}) => {

    useEffect(() => {
        window.scrollTo({top:0 })
    }, [])

	return (
        <div className={classNames(
            className,
            'page',
        )}>
            <Helmet>
				<title>{ pageTitle }</title>
				<meta
					name="description"
					content={pageDescription}
				/>

				<meta property="og:title" content={pageTitle} />
				<meta property="og:description" content={pageDescription} />
				<meta property="og:url" content={pageUrl} />
				<meta name="twitter:url" content={pageUrl} />
				<meta name="twitter:title" content={pageTitle} />
				<meta name="twitter:description" content={pageDescription} />
				<meta property="og:site_name" content={pageTitle} />
				<meta name="twitter:image:alt" content={pageDescription} />
				<meta property="twitter:text:title" content={pageTitle} />
			</Helmet>
            <div className='box-border'>
                <div className='flex flex-col'>
                        <ScrollAnimation animateOnce animateIn="fadeIn">
                            <div id="cover" className="mx-auto md:h-screen flex flex-col items-center justify-start font-sans min-h-96 bg-gray-50 lg:pt-10 lg:pb-20 lg:bg-hero dark:bg-black lg:bg-cover bg-center bg-opacity-40 bg-cover" style={{ backgroundImage : 'url(/images/hero_about.jpg)'}}>
                               <div
									className={classNames(
										'container max-w-3xl mx-auto mt-40 mb:mt-0 mb-0 md:mb-8',
									)}
								>
                                    <h1 className="p-0 text-3xl font-bold text-left text-white leading-normal lg:mx-auto lg:text-5xl pl-8 pr-8 mb-8">
                                    How are my tasks kept private and secure?
                                    </h1>
                                    <p className="p-0 font-sans text-base leading-normal text-left text-gray-400 lg:text-gray-400 pl-8 pr-8 mb-16">
                                      Yes, all your tasks are private by default. The only exception is when you add a task to a list that you explicitely decided to share with someone else.
                                    </p>
                                    <p className="p-0 font-sans text-base leading-normal text-left text-gray-400 lg:text-gray-400 pl-8 pr-8 mb-16">
                                      From a privacy perspective we take a lot of care about the data you store in Schedulist. When our employees look at the data potentially sensitive information (like task titles and descriptions) are hidden by default, because we don't want to accidentally read or expose your data.
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                </div>
            </div>
        </div>
	);
})`
    #cover {
        .container {
            z-index: 1;
        }
        &:after {
            display: block;
            background: rgba(0,0,0,0.7);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            pointer-events: none;
            @media (max-width: 600px) {
                height: calc(100% + 123px);
            }
        }
    }
`;

export default AreMyTasksPrivate