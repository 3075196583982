import React from 'react'
import Section from '../components/section'
import styled from 'styled-components';
import BottomBullets from '../components/bottom-bullets';
import Integrations from '../components/integrations'

const content = [
    {
        id: 0,
        type: 'intro',
        name: 'productivity-reinvented',
        legend: 'Make Your Tasks and Schedule',
        title: 'Swipeable, Glanceable and Dealwithable',
        background: '/images/bg.jpg',
        text: 'Reduce Cognitive Load and Become Limitless with Schedulist. Effortlessly put anything on your mind into Schedulist; tasks, links, ideas. Follow up on it when you have time.',
        class: 'flex flex-col items-center justify-start font-sans min-h-96 bg-gray-50 lg:pt-10 lg:pb-20 lg:bg-hero dark:bg-black bg-cover bg-center',
        animate: 'fadeIn',
    },
    {
        id: 1,
        name: 'one-daily-agenda',
        title: 'Effortless',
        asset: '/images/app-screens/01',
        darkAsset: '/images/app-screens/01_dark',
        text: 'Just type to add tasks. Just swipe to complete and plan tasks.',
        class: 'lg:items-center lg:flex lg:flex-row-reverse lg:justify-center',
        align: 'right',
        animate: 'fadeInLeft',
    },
    {
        id: 2,
        name: 'manage-workload',
        title: 'Add Anything',
        asset: '/images/app-screens/02',
        darkAsset: '/images/app-screens/02_dark',
        text: 'Intuitively add images, files and links. Add notes and comments - with markdown and checklist support.',
        class: 'lg:items-center lg:flex lg:flex-row lg:justify-center',
        align: 'left',
        animate: 'fadeInRight',
    },
    {
        id: 3,
        name: 'organise-and-collaborate-with-lists',
        title: 'Shared lists',
        asset: '/images/app-screens/03',
        darkAsset: '/images/app-screens/03_dark',
        text: 'Invite family, friends and colleagues and get things done together.',
        class: 'lg:items-center lg:flex lg:flex-row-reverse lg:justify-center',
        align: 'right',
        animate: 'fadeInLeft',
    },
    {
        id: 4,
        name: 'swipable-and-talkable',
        title: 'Schedule',
        asset: '/images/app-screens/04',
        darkAsset: '/images/app-screens/04_dark',
        text: 'Recurring tasks, deadlines and your calendars in one place.',
        class: 'lg:items-center lg:flex lg:flex-row lg:justify-center',
        align: 'left',
        animate: 'fadeInRight',
    },
    {
        id: 5,
        name: 'less-stress-morepeace-of-mind',
        title: 'Reduce Overwhelm',
        asset: '/images/app-screens/05',
        darkAsset: '/images/app-screens/05_dark',
        text: 'In every design detail we strive to reduce overwhelm and simplify. Get more done - with less stress.',
        class: 'lg:items-center lg:flex lg:flex-row-reverse lg:justify-center',
        align: 'right',
        animate: 'fadeInLeft',
    },
];

const Home = styled(({className}) => {
	return (
        <div className='box-border'>
            <div className='flex flex-col'>
                <Section content={content} />

                <BottomBullets />
                <Integrations />
            </div>
        </div>
	);
})`
`;

export default Home
