import classNames from 'classnames';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import Download from './download';

const Hero = styled(({ className, item }) => {	
    return (
        <ScrollAnimation
            key={item.id}
            animateOnce
            animateIn={item.animate ? item.animate : 'none'}
        >
            <div
                className={classNames(
                    className,
                    'section align-center justify-center',
                    item.type === 'intro' ? 'md:h-screen' : 'container mx-auto',
                    item.class,
                    item.align,
                )}
                id={item.name}
                style={{ backgroundImage: `url(${item.background}` }}
            >
                    <div
                        className={classNames(
                            'container max-w-3xl mx-auto mt-40 mb:mt-0 mb-0 md:mb-8',
                        )}
                    >
                        <h1>
                            <p className="p-0 text-xs font-bold uppercase text-left schedulist-primary lg:mx-auto lg:text-2xl pl-8 pr-8 mb-8">
                                {item.legend}
                            </p>
                            <p className="p-0 text-3xl font-bold text-left text-white leading-normal lg:mx-auto lg:text-5xl pl-8 pr-8 mb-8">
                                {item.title}
                            </p>
                        </h1>
                        <h2 className="p-0 font-sans text-base leading-normal text-left text-gray-400 lg:text-gray-400 pl-8 pr-8">
                            {item.text}
                        </h2>
                        <Download />
                        {item.asset ? (
                            <div className="ml-10 lg:ml-0 lg:w-3/6">
                                <img
                                    alt="demo"
                                    className="lg:mt-24 lg:mb-20"
                                    src={item.asset}
                                />
                            </div>
                        ) : null}
                    </div>
            </div>
        </ScrollAnimation>
    );
})``;

export default Hero;