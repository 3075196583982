import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const Links = [
	/*{
		icon: '/images/icon_testflight@2x.png',
		title: 'Get the Beta on',
		name: 'TestFlight',
		url: 'https://testflight.apple.com/join/FL6CgWnA',
	},*/
	{
		icon: '/images/icon_appstore@2x.png',
		title: 'Download on',
		name: 'App Store',
		url: 'https://apps.apple.com/se/app/schedulist-tasks-and-planner/id1568459470?l=en',
	},
	{
		icon: '/images/icon_googleplay@2x.png',
		title: 'Download on',
		name: 'Google Play',
		url: 'https://play.google.com/store/apps/details?id=com.kingstinct.schedulist',
	},
	{
		icon: '/images/icon_webapp@2x.png',
		title: 'Use the',
		name: 'Web App',
		url: 'https://web.schedulist.app',
	}
];

const Download = styled(({className}) => {
	return (
		<div
			className={classNames(className,'downloads w-full md:w-max flex flex-col md:flex-row items-center justify-center mt-8 md:mt-8 mb-4 md:mb-8 pl-8 pr-8 md:pl-6 md:pr-6')}
		>
			{Links.map((link) => (
				<div
					key={link.url}
					className={classNames(
						'download bg-opacity-60 w-full md:w-max mb-4 md:mb-0',
						link > 1 ? '' : 'mr-2 ml-2',
					)}
				>
					<a
						href={link.url}
						className={classNames('flex flex-row items-center p-2 pl-2 md:pl-2 pr-8 md:pr-4 w-100 justify-start rounded')}
					>
						<img src={link.icon} alt="icon" className={classNames('max-h-8')} />
						<p
							className={classNames(
								'text-gray-300 flex flex-col items-start justify-center pl-2 text-xxs',
							)}
						>
							{link.title}
							<span className={classNames('text-xs')}>{link.name}</span>
						</p>
					</a>
				</div>
			))}
		</div>
	);
})`
	&:hover {
		.download {
			a {
				opacity: 0.7;
			}
		}
	}
	.download {
		a {
			background: rgba(255,255,255,0.15);
			min-width: 130px;
			transition: all 0.1s linear;
			transform: scale(1.000001);
			&:hover {
				opacity: 1;
				transform: scale(1.1);
			}
			&.rounded {
				border-radius: 6px;
			}
		}
	}

	.download img {
		border-radius: 6px;
	}
`;

export default Download;
