import React, { useEffect } from 'react'
import classNames from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const pageTitle = 'About | Schedulist'
const pageDescription = 'The Effortless Productivity Platform'
const pageUrl = 'https://www.schedulist.app/about'

const About = styled(({className}) => {

    useEffect(() => {
        window.scrollTo({top:0 })
    }, [])

	return (
        <div className={classNames(
            className,
            'page',
        )}>
            <Helmet>
				<title>{ pageTitle }</title>
				<meta
					name="description"
					content={pageDescription}
				/>

				<meta property="og:title" content={pageTitle} />
				<meta property="og:description" content={pageDescription} />
				<meta property="og:url" content={pageUrl} />
				<meta name="twitter:url" content={pageUrl} />
				<meta name="twitter:title" content={pageTitle} />
				<meta name="twitter:description" content={pageDescription} />
				<meta property="og:site_name" content={pageTitle} />
				<meta name="twitter:image:alt" content={pageDescription} />
				<meta property="twitter:text:title" content={pageTitle} />
			</Helmet>
            <div className='box-border'>
                <div className='flex flex-col'>
                        <ScrollAnimation animateOnce animateIn="fadeIn">
                            <div id="cover" className="mx-auto md:h-screen flex flex-col items-center justify-start font-sans min-h-96 bg-gray-50 lg:pt-10 lg:pb-20 lg:bg-hero dark:bg-black lg:bg-cover bg-center bg-opacity-40 bg-cover" style={{ backgroundImage : 'url(/images/hero_about.jpg)'}}>
                               <div
									className={classNames(
										'container max-w-3xl mx-auto mt-40 mb:mt-0 mb-0 md:mb-8',
									)}
								>
                                    <h1 className="p-0 text-3xl font-bold text-left text-white leading-normal lg:mx-auto lg:text-5xl pl-8 pr-8 mb-8">
                                        About
                                    </h1>
                                    <p className="p-0 font-sans text-base md:text-xl leading-xl text-left text-gray-300 dark:text-gray-300 lg:text-gray-300 mb-8 pl-8 pr-8">
                                        Kingstinct has always strived to make a positive impact in peoples lives. One of our ventures into improving life quality is through productivity apps - previously through Lyster and now through Schedulist. The impact we hope to make is to help improve personal productivity while reducing cognitive load and stress.
                                    </p>
                                    <p className="p-0 font-sans text-base leading-normal text-left text-gray-400 lg:text-gray-400 pl-8 pr-8 mb-16">
                                        Most Task Management apps have some issues that we think Schedulist improves on:
                                        <ul className="p-0 font-sans text-base leading-normal text-left text-gray-400 lg:text-gray-400 py-8">
                                            <li>They don't take <b>cognitive load</b> into consideration</li>
                                            <li>They require <b>constant management</b> - long lists with out-of-date tasks is usually the result - which in turn increases cognitive load</li>
                                            <li>They don't give much <b>sense of accomplishment</b></li>
                                            <li>They are not <b>self-sufficient</b>. You often need to keep track of your calendar and team tools in addition, which makes it easy to miss things and increases stress</li>
                                        </ul>
                                        We try to reduce cognitive load above all. We always ask ourselves how to make something as simple as possible - instead of mindlessly adding features on top of one another.
                                    </p>
                                </div>
                            </div>
                            {/* <div
                                className={classNames(
                                    className,
                                    'section align-center justify-center container mx-auto mt-40',
                                )}
                            >
                                <div className={classNames('container max-w-3xl  mx-auto mt-8 md:mt-16 mb:mt-0 mb-0 md:mb-16')}>
                                    <p className="p-0 font-sans text-base md:text-xl leading-xl text-left text-gray-600 dark:text-gray-300 lg:text-gray-600 mb-4">
                                        Lorem ipsum dolor sit amet, ei utamur eligendi scriptorem qui. Tation animal id pri. No dolorem euripidis his, eum dico vulputate at, ei his erant dissentiet. Modus utroque pri in, no eam graeco torquatos necessitatibus. Sit liber ullamcorper eu, veri primis aliquando eum te. Oratio persius pro ei. Illud epicuri id vel, his purto iracundia ex. Vocent placerat his ad, est ei dicat fabulas appetere.
                                    </p>
                                    <p className="p-0 font-sans text-base leading-normal text-left text-gray-400 lg:text-gray-400">
                                        Lorem ipsum dolor sit amet, ei utamur eligendi scriptorem qui. Tation animal id pri. No dolorem euripidis his, eum dico vulputate at, ei his erant dissentiet. Modus utroque pri in, no eam graeco torquatos necessitatibus. Sit liber ullamcorper eu, veri primis aliquando eum te. Oratio persius pro ei. Illud epicuri id vel, his purto iracundia ex. Vocent placerat his ad, est ei dicat fabulas appetere.
                                    </p>
                                </div>
                            </div> */}
                        </ScrollAnimation>
                </div>
            </div>
        </div>
	);
})`
    #cover {
        .container {
            z-index: 1;
        }
        &:after {
            display: block;
            background: rgba(0,0,0,0.7);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            pointer-events: none;
            @media (max-width: 600px) {
                height: calc(100% + 123px);
            }
        }
    }
`;

export default About